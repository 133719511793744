@mixin embedAbsolute() {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ↓ vimeo レスポンシブ対応 */
.embed-container {
  --video--width: 1296;
  --video--height: 750;

  position: relative;
  padding-bottom: calc(var(--video--height) / var(--video--width) * 100%); /* 41.66666667% */
  // overflow: hidden;
  max-width: 100%;
  background: #ffffff;

  iframe {
    @include embedAbsolute()
  }
  object {
    @include embedAbsolute()
  }
  embed {
    @include embedAbsolute()
  }
}
/* ↑ vimeo レスポンシブ対応 */

.player {
  .vp-title {
    .vp-title-header {
      h1 {
        display: none!important;
      }
      .sub-title {
        display: none!important;
      }
    }
  }

  .vp-sidedock {
    .box {
      display: none!important;
    }
  }

  .headers {
    display: none!important;
  }
}
