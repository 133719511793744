.signform {
  div.signform_input {
    margin: 10px 0;
    width: 100%;
    // input {
    //   color: $primary_light;
    //   border-radius: 5px;
    // }
  }
}
