// ./materialui/theme.js > commonTheme.palette

$black: #1c1e21;

$primary_light: #A8C0DD;
$primary_main: #395475;
$primary_dark: #072c49;

$secondary_light: #FFF5E4;
$secondary_main: #f29f1c;
$secondary_dark: #ba7000;

$error_light: #E66D45;
$error_main: #E03800;
$error_dark: #C73200;
