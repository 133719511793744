$comment_avatar_width: 30px;

.comment_item {
  margin: 5px 0;

  &.MuiListItem-gutters {
    padding: 0;
  }

  .MuiAvatar-root {
    &.comment_avatar {
      width: $comment_avatar_width;
      height: $comment_avatar_width;
    }
  }

  .comment_item_avater {
    min-width: $comment_avatar_width + 10;
  }

  .comment_item_icons {
    display: flex;
  }

  .comment_item_txt {
    background: #eee;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    min-width: 0;
    width: 100%;

    &> .comment_item_txt_name {
      font-weight: bold;
    }

    &> .comment_item_txt_time {
      text-align: right;
    }

    &> p {
      line-height: 1.2;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.comment_item_txt_open {
      &> p {
        line-height: 1.2;
        white-space: normal;
        overflow: unset;
        text-overflow: unset;
      }
    }

    .edit_wrap {
      display: flex;

    }
  }

  .not_pointer {
    cursor: unset;
  }
}
// コメント時のアイコンは小さめ
