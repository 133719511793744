body {
   color: black;
}

.MuiCard-root {
   border: 1px rgba(224, 224, 224, 1) solid;
}

// \nを改行して表示するスタイル
.indention {
   white-space: pre-line;
   overflow-wrap: break-word;
}

// スクロールバー
*::-webkit-scrollbar {
  height: 10px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
}
*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  // background-color: $primary_main;
  background-color: $primary_main;
}

// スマホのフォーム入力時の拡大を制御
input {
  font-size: 16px!important;
  transform: scale(0.87)!important;
  margin-left: -6%!important;
}

input[type="file" i] {
  margin-left: -20px!important;
}
