.terms {
  margin: 10px 0;
  width: 100%;
  &_ttl {
    margin-bottom: 0,
  }
  &_txt {
    margin-bottom: 15px;
    margin-top: 5px;
    font-size: 0.8rem;
    &_p {
      margin: 0;
    }
  }
}
