.loading-bro {
   width: 100%;
   top: 12px;
   position: absolute;
   z-index: 9999;
   background: rgba(255,255,255,0.7);
   &-txt {
      position: absolute;
      width: 155px;
      text-align: center;
      margin-top: 65px;
      font-weight: bold;
      color: $primary_main;
   }
}

.loading-comment {
   width: 100%;
   top: -41px;
   position: absolute;
   z-index: 9999;
   background: rgba(255,255,255,0.7);
   &-txt {
      position: absolute;
      width: 155px;
      text-align: center;
      margin-top: 65px;
      font-weight: bold;
      color: $primary_main;
   }
}

#load {
    width: 150px;
    animation: loading 3s linear infinite;
    #loading-inner {
      stroke: {
        dashoffset: 0;
        dasharray: 300;
        width: 10;
        miterlimit: 10;
        linecap: round;
      }
      animation: loading-circle 2s linear infinite;
      stroke: #51BBA7;
      fill: transparent;
    }
  }

@keyframes loading {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
}
@keyframes loading-circle {
    0% {
      stroke-dashoffset: 0
    }
    100% {
      stroke-dashoffset: -600;
    }
}
